import React from 'react';

const TriggeredAlert = ({ alert }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
      <span style={{ marginRight: '10px' }}>✔️</span>
      <span>{`${alert.key} = ${alert.value}`}</span>
    </div>
  );
};

export default TriggeredAlert;
