import logo from './logo.svg';
import './App.css';
import Main from './components/Main';

import React, { useEffect } from 'react';

function App() {

  //const convertedVapidKey = urlBase64ToUint8Array('BJQQjTmVfPuR6uGH6ya3skmEdUK2dIajoxnP7vaJuYgvf-sogXkxNRARDs5Y1MGtiyo46qTjgjU2usVuJAJ6G7g');

  // useEffect(() => {
  //   function subscribeUser() {
  //     if ('serviceWorker' in navigator) {
  //       navigator.serviceWorker.ready.then(function(registration) {
  //         if (!registration.pushManager) {
  //           alert('Push notifications not supported');
  //           return;
  //         }
  //         registration.pushManager.subscribe({
  //           userVisibleOnly: true,
  //           applicationServerKey: convertedVapidKey
  //         }).then(function(subscription) {
  //           console.log('Push subscription:', subscription);
  //           console.log('Subscription details:', JSON.stringify(subscription, null, 2));
  //           // Send subscription to the backend
  //           fetch('https://localhost:7068/Home/Subscribe', {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify(subscription),
  //           });
  //         }).catch(function(error) {
  //           console.error('Error during subscribe:', error);
  //         });
  //       });
  //     }
  //   }
    
  //   subscribeUser();
  // }, []);

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }


  return (
    <div className="App">
      <header className="App-header">
        <Main/>
        
      </header>
    </div>
  );
}

export default App;
