import React, { useState, useEffect } from 'react';
import '../App.css';

const AlertsList = ({ onSave, baseUrl }) => {
  const [alerts, setAlerts] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await fetch(baseUrl + 'GetAlerts');
        const data = await response.json();
        setAlerts(data);
      } catch (error) {
        console.error('Failed to fetch alerts:', error);
      }
    };

    fetchAlerts();
  }, []);

  const handleCheckboxChange = (key) => {
    setCheckedKeys(prevKeys => {
      // Check if the key is already in the array
      if (prevKeys.includes(key)) {
        // Remove the key if it's already included (checkbox is being unchecked)
        return prevKeys.filter(k => k !== key);
      } else {
        // Add the key if it's not included (checkbox is being checked)
        return [...prevKeys, key];
      }
    });
  };

  const handleSaveClick = () => {
    onSave(checkedKeys);
  };

  return (
    <div>
      <h1>Alerts</h1>
      <ul className="alerts-list">
        {alerts.map(alert => (
          <li key={alert.key} className="alerts-list-item">
            <label>
              <input
                type="checkbox"
                checked={checkedKeys.includes(alert.key)}
                onChange={() => handleCheckboxChange(alert.key)}
              />
              {alert.displayName}
            </label>
          </li>
        ))}
      </ul>
      <button onClick={handleSaveClick}>Save</button>
    </div>
  );
};

export default AlertsList;
