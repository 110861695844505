import React, { useState, useEffect } from 'react';
import ImageCapture from './ImageCapture';
import '../App.css'; // Make sure to import your CSS here
import AlertsList from './AlertsList';
import TriggeredAlert from './TriggeredAlert';

const Main = () => {
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false); // State to track loading
    const [showAlertsList, setShowAlertsList] = useState(false);
    const [alertsAreSet, setAlertsAreSet] = useState(false);
    const [triggeredAlerts, setTriggeredAlerts] = useState([]);
    const [isCaptureMode, setIsCaptureMode] = useState(false);

    //const baseUrl = 'https://localhost:7068/Home/'; //localhost
    const baseUrl = '/Home/'; //production'

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('capture')) {
          setIsCaptureMode(true);
        }
      }, [window.location.search]); // Dependency array includes `window.location.search`
    

    const handleSave = async (checkedAlerts) => {

        setShowAlertsList(false);
        setDescription("");
        console.log('Checked alerts:', checkedAlerts);
        
        const requestBody = {
                checkedAlerts: checkedAlerts,
            };
        
            const response = await fetch(baseUrl + 'SetAlerts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(checkedAlerts)
            });
            setAlertsAreSet(true);
      };

      

    const sendImageToServer = async (imageDataUrl) => {
        setIsLoading(true); // Start loading
        try {
            console.log("imageLength: ", imageDataUrl.length);
        
            const requestBody = {
                ImageBase64: imageDataUrl,
                FileExtension: 'jpeg',
                prompt: 'Describe this image very briefly'
            };
        
            const response = await fetch(baseUrl + 'Describe', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
        
            const data = await response.json();
            console.log(data);

            setDescription(data);
            setTriggeredAlerts(parseAlerts(data));

        } catch (error) {
            console.error(error);
        }
        setIsLoading(false); // Stop loading
    };

    const parseAlerts = (alerts) => {
        return alerts.map(item => {
          const [key, value] = item.trim().split('=');
          return { key, value };
        });
      };

    return (
        <div>
            {isLoading && <div className="spinner-container"><div className="spinner"></div></div> }
            
            <div>
                {triggeredAlerts.map((alert, index) => (
                    <TriggeredAlert key={alert.key} alert={alert} />
                ))}
            </div>

            {!showAlertsList &&
                <button onClick={() => setShowAlertsList(!showAlertsList)}> Configure Alerts</button>
                
            }
      

            {!showAlertsList &&
                <ImageCapture onCapture={sendImageToServer} baseUrl={baseUrl}/>
            }
            
            {showAlertsList &&
                <AlertsList onSave={handleSave} baseUrl={baseUrl}/>
            }           
        </div>
    );
};

export default Main;
