import React, { useState } from 'react';
import '../App.css'; // ensure you import your CSS file

const ImageCapture = ({ onCapture}) => {
    const [resizedImage, setResizedImage] = useState(null);
    const fileInputRef = React.useRef(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                resizeImage(e.target.result, 512); // Resize so longest side is 512px
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };
            reader.readAsDataURL(file);
        }
    };

    const resizeImage = (base64Str, maxSize) => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxSize) {
                    height *= maxSize / width;
                    width = maxSize;
                }
            } else {
                if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            const resizedDataURL = canvas.toDataURL();
            setResizedImage(resizedDataURL);
            onCapture(resizedDataURL);
        };
        img.onerror = () => {
            console.error('Error loading image');
        };
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className="image-capture-container">
            <input 
                type="file" 
                accept="image/*" 
                capture="environment" 
                onChange={handleImageChange} 
                ref={fileInputRef} 
                style={{ display: 'none' }} // Hide the actual input element
            />
            <button onClick={handleButtonClick}>Capture Image</button>
            {resizedImage && <img src={resizedImage} alt="Resized" style={{ maxWidth: '100%' }} />}
        </div>
    );
};

export default ImageCapture;
